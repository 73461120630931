* {
  font-size: 16px;
}

.ui-table .ui-table-tbody > tr > td {
  height: 22px !important;
  font-size: 13px !important;
  font-weight: normal;
}

.ui-table .ui-table-thead > tr > th {
  font-size: 14px !important;
  height: 30px !important;
  font-weight: bold;
  text-align: center !important;
}

/* .ui-multiselect-panel .ui-multiselect-item {
  position: fixed !important;
} */

.c-form-input, .c-form-select {
  text-align: center !important;
}

@media print {
  body * {
    overflow: visible !important;
    visibility: visible;
    width: 100% !important;
  }

  .print-content * {
    overflow: visible !important;
    visibility: visible;
    left: 0;
    top: 0;
    margin: 0;
    padding: 5px;
  }

  a, .no-print {
    display: none !important;
    visibility: hidden !important;
  }
}
